export default {
  namespaced: true,

  state: () => ({
    darkmode: true,
    globalLang: '',
    isSidebarOpen: false,
    isPageLoading: false,
  }),

  mutations: {
    setIsPageLoading(state, payload) {
      state.isPageLoading = payload;
    },

    setIsSidebarOpen(state, payload) {
      state.isSidebarOpen = payload;
    },

    setDarkmode(state, payload) {
      state.darkmode = payload;
    },

    setGlobalLang(state, payload) {
      state.globalLang = payload;
    },
  },

  actions: {
    initSettings({ commit }) {
      // Get stored setting for darkmode
      const storedDarkmode = localStorage.getItem('darkmode');

      if (storedDarkmode === 'false') {
        commit('setDarkmode', false);
      }

      // Get stored setting for language
      const storedLang = localStorage.getItem('globalLang');

      if (storedLang) {
        commit('setGlobalLang', storedLang);
      }
    },

    setIsPageLoading({ commit }, payload) {
      commit('setIsPageLoading', payload);
    },

    openSidebar({ commit }) {
      commit('setIsSidebarOpen', true);
    },

    closeSidebar({ commit }) {
      commit('setIsSidebarOpen', false);
    },

    toggleDarkmode({ commit, state }) {
      const darkmode = !state.darkmode;

      commit('setDarkmode', darkmode);
      localStorage.setItem('darkmode', darkmode);
    },

    setGlobalLang({ commit }, payload) {
      commit('setGlobalLang', payload);
      localStorage.setItem('globalLang', payload);
    },
  },
};
