<script>
import { mapActions } from 'vuex';

export default {
  created() {
    this.initSettings();
  },

  mounted() {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });

    document.body.addEventListener('keydown', (event) => {
      if (event.key === 'Tab') {
        document.body.classList.remove('using-mouse');
      }
    });
  },

  methods: {
    ...mapActions('settings', ['initSettings']),
  },
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>
