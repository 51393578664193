import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/api',
    component: () => import('@/views/apiReference/ApiReference.vue'),
    children: [
      {
        path: '',
        name: 'home-api-reference',
        component: () => import('@/views/apiReference/HomeApiReference.vue'),
      },
      {
        path: ':slug',
        name: 'page-api-reference',
        component: () => import('@/views/apiReference/PageApiReference.vue'),
      },
      {
        path: ':slug/:version',
        name: 'version-api-reference',
        component: () => import('@/views/apiReference/PageApiReference.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/documentation/Documentation.vue'),
    children: [
      {
        path: '',
        name: 'home-documentation',
        component: () => import('@/views/documentation/HomeDocumentation.vue'),
      },
      {
        path: 'faq',
        name: 'page-faq',
        component: () => import('@/views/documentation/PageFaq.vue'),
      },
      {
        path: 'faq/:slug',
        name: 'page-faq-question',
        component: () => import('@/views/documentation/PageFaqQuestion.vue'),
      },
      {
        path: 'contact-us',
        name: 'page-contact',
        component: () => import('@/views/documentation/PageContact.vue'),
      },
      {
        path: 'changelog',
        name: 'page-changelog',
        component: () => import('@/views/documentation/PageChangelog.vue'),
      },
      {
        path: 'search',
        name: 'page-search',
        component: () => import('@/views/documentation/PageSearch.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page',
        component: () => import('@/views/documentation/PageDocumentation.vue'),
      },
    ],
  },
];

export const router = new createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // No delay if we do not change page
    if (to.hash && to.hash !== from.hash && to.path === from.path) {
      return { el: to.hash };
    }

    // Delay scroll otherwise so the content has time to load
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash });
        }, 500);
      });
    }

    // Do not scroll on query changes
    if (to.path === from.path) {
      return false;
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
});
