import './registerServiceWorker';

import { createApp } from 'vue';
import { router } from './router';
import { store } from './store';
import { observe } from './directives/observe';

import '@getswish/design-library-web/dist/style.css';
import '@getswish/design-library-web/scss/styles/index.scss';
import '@/scss/styles/index.scss';

import App from './App.vue';

const app = createApp(App);

app.use(router);
app.use(store);

app.directive('observe', observe);

app.mount('#app');
