import { createStore } from 'vuex';

import menu from './menu';
import settings from './settings';

export const store = createStore({
  modules: {
    menu,
    settings,
  },
});
