export default {
  namespaced: true,

  state: () => ({
    menuMap: null,
    sectionsMenu: null,
    sectionsInView: [],
  }),

  mutations: {
    setMenuStructure(state, payload) {
      state.menuMap = payload;
    },

    setSectionsMenu(state, payload) {
      state.sectionsMenu = payload;
    },

    setSectionsInView(state, { isVisible, id }) {
      if (isVisible) {
        state.sectionsInView.push(id);
      } else {
        state.sectionsInView = state.sectionsInView.filter((sectionId) => sectionId !== id);
      }
    },
  },

  actions: {
    setMenuStructure({ commit }, { mainMenu, parent }) {
      const menuMap = new Map();

      const createMenu = (menu, parents, level) => {
        menu.forEach((item) => {
          const slug = item.fields.link && item.fields.link.fields && item.fields.link.fields.slug;

          if (slug) {
            menuMap.set(slug, parents);

            if (item.fields.subMenu && level < 3) {
              const newLevel = level + 1;
              createMenu(item.fields.subMenu, [...parents, slug], newLevel);
            }
          }
        });
      };

      createMenu(mainMenu, parent ? [parent] : [], 0);

      commit('setMenuStructure', menuMap);
    },

    setSectionsMenu({ commit }, { sections }) {
      const sectionsMenu = sections.flatMap((section) => [section, ...section.subsections]);
      commit('setSectionsMenu', sectionsMenu);
    },

    clearSectionsMenu({ commit }) {
      commit('setSectionsMenu', null);
    },

    setSectionsInView({ commit }, { isVisible, id }) {
      commit('setSectionsInView', { isVisible, id });
    },
  },

  getters: {
    getActiveSection: (state) => {
      const sectionIds = state.sectionsMenu.map((section) => section.tagId);
      return sectionIds.find((section) => state.sectionsInView.includes(section));
    },

    getPageLink: (state) => (link) => {
      if (link.fields.url) {
        return link.fields.url;
      }

      const { slug } = link.fields;
      const { id } = link.sys.contentType.sys;

      if (id === 'apiReferencePage') {
        return `/api/${slug}`;
      }

      if (!state.menuMap) {
        return `/${slug}`;
      }

      const parents = state.menuMap.get(slug);

      if (!parents || !parents.length) {
        return `/${slug}`;
      }

      const path = parents.reduce((acc, cur) => `${acc}/${cur}`, '');
      return `${path}/${slug}`;
    },
  },
};
