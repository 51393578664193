let observer;

export const observe = {
  mounted(element, binding) {
    if (!('IntersectionObserver' in window)) {
      return;
    }

    const handleIntersect = (el) => {
      const e = el[0];
      binding.value({ isVisible: e.isIntersecting, id: e.target.id });
    };

    observer = new IntersectionObserver(handleIntersect, { rootMargin: '-100px' });
    observer.observe(element);
  },

  beforeUnmount() {
    observer.disconnect();
  },
};
